import React from "react";
import "./about.scss";
import storeImg from "../../images/store.webp";

export default function About() {
  return (
    <section className="about-section mb-32" id="about-us">
      <div className="container">
        <div className="flex items-center">
          <div className="flex-1">
            <img src={storeImg} width={500} />
          </div>
          <div
            className="md:flex-1 md:pr-12 wow animate__fadeInUp"
            data-wow-duration="2s"
          >
            <h2 className="text-3xl mb-6 font-bold">نبذة عن البرنامج</h2>
            <p className="leading-8 text-gray-700">
              يعد مايند اكونتينج هو البرنامج المثالي لاصحاب المنشأت والمشاريع ,
              يساعدهم في إدارة جميع الجوانب الإِدارية , وبواجهة برمجية سهلة
              الاستخدام حتى تتيح لكل افراد المنشأة او المشروع العمل عليه بسلاسة
              , يمتاز البرنامج بسرعة إنجار المهام في جميع الجوانب المحاسبية
              والإدارية , يمكنك من اصدار فواتير وتقارير و إدارة مبيعاتك ومخزونك
              وعملائك وموردينك وموظفيظ وحساباتك و اكثر , يمكنك إدارة ومتابعة
              نشاطك من اى مكان كون النظام سحابى معد لذلك.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
