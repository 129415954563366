import { Link } from "gatsby";
import React from "react";
import { Header } from "../components/Header/Header";
import Layout from "../components/layout";

const Learn = () => {
  const groups = [
    {
      title: "المبيعات",
      lessons: [
        {
          title: "فواتير المبيعات",
          to: "/",
        },
        {
          title: "مرتجع المبيعات",
          to: "/",
        },
        {
          title: "العملاء",
          to: "/",
        },
        {
          title: "التحصيل",
          to: "/",
        },
      ],
    },
    {
      title: "المشتريات",
      lessons: [
        {
          title: "فواتير المشتريات",
          to: "/",
        },
        {
          title: "مرتجع المشتريات",
          to: "/",
        },
        {
          title: "الموردين",
          to: "/",
        },
        {
          title: "السداد",
          to: "/",
        },
      ],
    },
    {
      title: "الحسابات العامة",
      lessons: [
        {
          title: "قيود اليومية",
          to: "/",
        },
        {
          title: "تحويلات النقدية",
          to: "/",
        },
        {
          title: "سندات الإيداع",
          to: "/",
        },
        {
          title: "سندات السحب",
          to: "/",
        },
      ],
    },
    {
      title: "الأصناف",
      lessons: [
        {
          title: "جميع الأصناف",
          to: "/",
        },
        {
          title: "التصنيفات",
          to: "/",
        },
        {
          title: "إعداد الوحدات",
          to: "/",
        },
        {
          title: "طباعة الباركود",
          to: "/",
        },
        {
          title: "انواع الاصناف",
          to: "/",
        },
      ],
    },
    {
      title: "التقارير",
      lessons: [
        {
          title: "التقارير المالية",
          to: "/",
        },
        {
          title: "التقارير المحاسبية",
          to: "/",
        },
        {
          title: "الفواتير",
          to: "/",
        },
        {
          title: "الموظفين",
          to: "/",
        },
        {
          title: "الضرائب",
          to: "/",
        },
        {
          title: "الأصول الثابتة",
          to: "/",
        },
      ],
    },
    {
      title: "المخازن",
      lessons: [
        {
          title: "جميع المخازن",
          to: "/",
        },
        {
          title: "جرد المخازن",
          to: "/",
        },
        {
          title: "مخزون أول",
          to: "/",
        },
        {
          title: "تحويلات المخازن",
          to: "/",
        },
      ],
    },
    {
      title: "الأصول الثابتة",
      lessons: [
        {
          title: "جميع الأصول",
          to: "/",
        },
        {
          title: "انواع الأصول",
          to: "/",
        },
        {
          title: "مشتريات الأصول",
          to: "/",
        },
        {
          title: "إهلاك أصول",
          to: "/",
        },
      ],
    },
    {
      title: "الأعدادات",
      lessons: [
        {
          title: "الفروع",
          to: "/",
        },
        {
          title: "الخزينة",
          to: "/",
        },
        {
          title: "دليل الحسابات",
          to: "/",
        },
        {
          title: "الضرائب",
          to: "/",
        },
        {
          title: "المستخدمين",
          to: "/",
        },
        {
          title: "الصلاحيات",
          to: "/",
        },
      ],
    },
  ];
  return (
    <Layout>
      <Header
        title="تعلم"
        caption1="سهلنا عليك استخدام البرنامج من خلال المحتوى المساعد"
      />
      <form>
        <input type="text" />
      </form>
      <div className="container my-8">
        <div className="md:flex md:-mx-3 flex-wrap">
          {groups.map((group, index) => (
            <div
              className="lg:w-1/4 md:w-1/3 px-3 mb-12"
              key={`group-${index}`}
            >
              <div className="bg-white border rounded-md mt-8 h-full ">
                <div className="bg-gray-100 text-purple-600 text-center rounded-t-lg font-medium px-3 py-4 relative">
                  <div className="-mt-10 text-center">
                    <span className="h-12 justify-center items-center w-12 inline-flex bg-white rounded-full border border-purple-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </span>
                  </div>
                  {group?.title}
                </div>
                <nav className="px-4 my-3">
                  {group.lessons.map((subGroup, i) => (
                    <div key={`linksGroup-${i}`}>
                      <Link
                        to="/learn/1"
                        className="text-xs hover:text-indigo-700 inline-flex items-center mb-3"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 ml-2"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        {subGroup?.title}
                      </Link>
                    </div>
                  ))}
                </nav>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};
export default Learn;
