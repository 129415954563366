import { Link } from "gatsby";
import React from "react";
import { Header } from "../components/Header/Header";
import Layout from "../components/layout";

const Policy = () => {
  return (
    <Layout>
      <Header
        title="سياسة الخصوصية"
        caption1="عاين سياسة الخصوصية قبل استخدام التطبيق"
      />
      <form>
        <input type="text" />
      </form>
      <div className="container my-8">
        <div className="bg-white p-4 shadow-lg rounded-md">
          <article className="leading-10">
            <h3 className="text-2xl mb-3 font-bold">
              أهلا بك في mind accounting
            </h3>
            <ol>
              <li className="mb-4">
                1. قبول الشروط - لكي تستخدم خدمات mind accounting يتعين عليك
                أولا أن توافق على الشروط، ولا يمكنك استعمال الخدمات قبل قبولك
                بالشروط. - يمكن قبول الشروط عبر أي من الخطوتين التاليتين: (‌أ)
                النقر على خيار القبول أو الموافقة على الشروط حيثما توفره لك mind
                accounting في واجهات المستخدم لأيٍ من الخدمات، أو التطبيقات.
                (‌ب) استعمال الخدمات فعلياً. وفي هذه الحالة، تدرك وتوافق أن mind
                accounting ستعتبر استخدامك للخدمات بمثابة قبول بالشروط من تلك
                اللحظة فصاعدا. - لا يجوز لك استعمال الخدمات ولا يمكنك قبول
                الشروط (أ) إذا لم تكن ضمن السن القانوني الذي يخولك إبرام عقد
                ملزم مع mind accounting، أو (ب) إذا كنت شخصاً ممنوعاً من تلقي
                الخدمات بمقتضى القوانين المعمول بها في البلد الذي تقيم فيه أو
                البلد الذي تستعمل الخدمات منه.
              </li>
              <li className="mb-4">
                2. تقديم الخدمات من قبل mind accounting - إن mind accounting
                تتطور باستمرار لكي توفر لمستخدميها أفضل خبرة ممكنة، وعليه، فإنك
                تقر وتوافق بأن شكل وطبيعة الخدمات التي توفرها mind accounting
                يمكن أن تتغير من وقت لآخر لإعطاعك افضل خدمة.
              </li>
              <li className="mb-4">
                3. استعمالك للخدمات - بغية الدخول إلى خدمات معينة، قد يتعين عليك
                تقديم بعض المعلومات الخاصة بك (مثل الاسم أو العنوان وتفاصيل
                الإتصال – اسم المؤسسة وبياناتها ) كجزء من عملية تسجيل اشتراكك في
                الخدمة، أو كجزء من استمرار استعمالك للخدمات. وتوافق على أن أية
                معلومات تقدمها لشركة mind accounting لغرض تسجيل اشتراكك ستكون
                دائماً صحيحة ودقيقة ومحدثة. - إنك توافق على استعمال الخدمات فقط
                للأغراض التي تمسح بها (أ) الشروط و(ب) القوانين أو الأنظمة أو
                القواعد العملية المقبولة عموماً أو التعليمات السائدة في الدولة
                المعنية (بما في ذلك أية قوانين تخص تصدير البيانات أو البرامج). -
                ما لم يتم السماح لك بذلك بموجب اتفاقية منفصلة مع mind accounting
                ، إنك توافق على عدم إعادة إنتاج الخدمات أو تصويرها أو نسخها أو
                بيعها أو المتاجرة فيها أو إعادة بيعها لأي غرض. - إنك توافق على
                أنك وحدك المسؤول (وأن mind accounting ليست مسؤولة بأي شكل تجاهك
                ولا تجاه أي طرف آخر) عن أي إخلال بالتزاماتك المترتبة عليك بموجب
                الشروط وعن نتائج ذلك الإخلال (بما في ذلك أية خسارة أو ضرر قد
                يلحق بـ mind accounting).
              </li>
              <li className="mb-4">
                4. أمن كلمات المرور والحساب الخاص بك - إنك تدرك وتوافق على أنك
                مسؤول عن المحافظة على سرية كلمات المرور الخاصة بأي حساب تستخدمه
                للدخول إلى الخدمات. - وبناء عليه، فإنك توافق على أن تكون المسؤول
                الوحيد تجاه mind accounting عن جميع الأنشطة التي تتم من خلال
                حسابك. - إذا علمت بأي استعمال غير مشروع لكلمة المرور الخاصة بك
                أو لحسابك، فإنك توافق على إبلاغ mind accounting بذلك على الفور.
              </li>
              <li className="mb-4">
                5. الخصوصية ومعلوماتك الشخصية - إنك توافق على استعمال بياناتك
                وفقا لسياسات الخصوصية لدى mind accounting.
              </li>
              <li className="mb-4">
                6. محتويات الخدمات - إنك تدرك أن استعمالك للخدمات قد يعرضك
                لمحتوى تجد أنه مزعج أو غير لائق أو غير مرغوب فيه، وتعلم أنك في
                هذا الشأن تستعمل الخدمات على مسؤوليتك. - إنك توافق على أنك وحدك
                المسؤول (وأن mind accounting لا تتحمل أية مسؤولية تجاهك أو تجاه
                أي طرف ثالث) عن المحتويات التي تنشئها بنفسك أو تنشرها أو تعرضها
                أثناء استعمالك للخدمات وعن أية نتائج تترتب على تصرفاتك (بما في
                ذلك أية خسارة أو ضرر يلحق بـ mind accounting) جراء ذلك.
              </li>
              <li className="mb-4">
                7. تحديث البرامج والأنظمة - إن البرامج والأنظمة الذي تستخدمها
                يمكن أن تحمل وتركب من وقت لآخر تحديثا من mind accounting. والهدف
                من هذا التحديث هو تحسين الخدمات وتطويرها ورفع مستواها ويمكن أن
                يكون على شكل إصلاح عيوب أو تحسين وظائف أو تركيبات برمجية جديدة
                أو نسخا جديدة بالكامل. وإنك توافق على استلام مثل هذه التحديثات
                (وتسمح لشركة mind accounting بتسليمها لك) كجزء من استعمالك
                للخدمات.
              </li>
              <li className="mb-4">
                8. الشروط العامة إنك توافق على أن mind accounting قد تزودك
                بإشعارات، بما فيها تلك المتعلقة بالتغييرات التي تطرأ على الشروط،
                بالبريد الإلكتروني أو البريد العادي أو بواسطة منشورات على
                الخدمات او اية وسيلة اخرى. إنك تدرك وتوافق على أنه إذا ما
                استعملت الخدمات بعد التاريخ الذي تعدل فيه الشروط العامة أوالشروط
                الإضافية، فإن mind accounting سوف تعتبر استعمالك للخدمات بمثابة
                قبول بالشروط العامة والشروط الإضافية المعدلة.
              </li>
              <li className="mb-4">
                8. الشروط العامة إنك توافق على أن mind accounting قد تزودك
                بإشعارات، بما فيها تلك المتعلقة بالتغييرات التي تطرأ على الشروط،
                بالبريد الإلكتروني أو البريد العادي أو بواسطة منشورات على
                الخدمات او اية وسيلة اخرى. إنك تدرك وتوافق على أنه إذا ما
                استعملت الخدمات بعد التاريخ الذي تعدل فيه الشروط العامة أوالشروط
                الإضافية، فإن mind accounting سوف تعتبر استعمالك للخدمات بمثابة
                قبول بالشروط العامة والشروط الإضافية المعدلة.
              </li>
            </ol>
          </article>
        </div>
      </div>
    </Layout>
  );
};
export default Policy;
