import { Link } from "gatsby";
import React from "react";
import { Header } from "../components/Header/Header";
import Layout from "../components/layout";
import { FormattedMessage, IntlProvider } from "react-intl";
import SimpleLocalize from "../components/i18n/IntlProvider";
import valueImg from "../images/value.png";
import cogwheel from "../images/cogwheel.png";
import mail from "../images/mail.png";
import aboutbg from "../images/about-bg.png";

const About = (props) => {
  return (
    <SimpleLocalize {...props}>
      <Layout>
        <Header
          title="من نحن"
          caption1="تعرف اكثر عن مؤسسة بيرى وكيف نخدم جميع القطاعات"
        />
        <div className="flex-1">
          <section className="py-20 px-6">
            <main className="lg:container mx-auto">
              <div className="flex md:flex-row flex-col gap-8 items-center">
                <div className="md:w-1/2">
                  <h3 className="text-xl font-medium text-primary mb-2">
                    <i className="fa fa-angle-double-left" />
                    <FormattedMessage
                      id="start"
                      defaultMessage="The beginning"
                    />
                  </h3>
                  <p className="text-gray-600 font-medium text-sm mb-8 leading-8">
                    <FormattedMessage
                      id="desc"
                      defaultMessage="بيري شركة ذات مسؤولية محدودة بدأت فى عام 2020 بهدف واضح ومهمة محددة وهى مساعدة التجار لتكون الشريك التقني المتكامل الذي يقدم جميع الحلول التقنية التى تسهل عليه إدارة تجارتهم"
                    />
                  </p>
                  <h3 className="text-xl font-medium text-primary mb-2">
                    <i className="fa fa-angle-double-left" />
                    نجاحات بيري
                  </h3>
                  <p className="text-gray-600 font-medium text-sm mb-8 leading-8">
                    <FormattedMessage
                      id="berry_success"
                      defaultMessage="Through its successful journey, it was able to provide the latest technical solutions for point-of-sale activities and accounting programs until it was able to provide the best services to customers, hoping for leadership in this field, thanks to reliance on God and then expertise in the world of technology, in addition to a homogeneous team that is constantly flying, as well as a unique service characterized by ease of procedures and speed of work. Accuracy in appointments and providing attractive, unbeatable prices linked to the best features and services"
                    />
                  </p>
                </div>
                <div className="md:w-1/2">
                  <img src={aboutbg} />
                </div>
              </div>
              <div className="md:w-1/2 mx-auto text-center space-y-4 mt-16">
                <img src={cogwheel} width={72} className="mx-auto mb-4" />
                <h3 className="text-xl font-bold">رؤيتنا</h3>
                <p className="text-gray-600  text-sm">
                  نحاول دائماً ابتكار أفضل الحلول التقنية التى تساعدنا فى تقديم
                  افضل تجربة لعملائنا
                </p>
              </div>
              <div className="md:w-1/2 mx-auto text-center space-y-4 mt-12">
                <img src={mail} width={72} className="mx-auto mb-4" />
                <h3 className="text-xl font-bold">مهمتنا</h3>
                <p className="text-gray-600  text-sm">
                  تقديم حلول مبتكرة تسهل على التجار أتمتة العمليات التشغيلة
                  لتقليل التكاليف وزيادة أرباح نشاطهم التجاري
                </p>
              </div>
              <div className="md:w-1/2 mx-auto text-center space-y-4 mt-12">
                <img src={valueImg} width={72} className="mx-auto mb-4" />
                <h3 className="text-xl font-bold">قيمنا</h3>
                <p className="text-gray-600  text-sm">
                  الإبتكار , المرونة , التجاوب , المصداقية
                </p>
              </div>
              <div
                id="contact"
                className="flex md:flex-row flex-col items-center mt-32"
              >
                <div className="md:w-1/2">
                  <h1 className="text-2xl font-bold">المقر</h1>
                  <p className="text-gray-600  text-sm mt-4">
                    هدفنا هو إرضاء عملائنا ، لذلك إذا واجهت أي مشكلة ، فيرجى
                    الاتصال بنا أو زيارة مقرنا الرئيسي
                  </p>
                  <ul className="space-y-4 mt-6">
                    <li>
                      <div className="flex gap-4">
                        <span className="shrink-0 text-gray-500">
                          <i className="fa-solid fa-location-dot fa-lg" />
                        </span>
                        <div className="space-y-1 flex-1">
                          <p className="text-gray-500 text-sm">الموقع</p>
                          <a
                            href="https://g.page/MindITksa?share"
                            className="font-semibold text-lg table"
                          >
                            Saudi Arabia Riyadh
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex gap-4">
                        <span className="shrink-0 text-gray-500">
                          <i className="fa-solid fa-phone fa-lg" />
                        </span>
                        <div className="space-y-1 flex-1">
                          <p className="text-gray-500 font-semibold text-sm">
                            رقم الهاتف
                          </p>
                          <a
                            href="http://we.me/9668003040054"
                            dir
                            className="font-semibold text-lg table tabular-nums"
                          >
                            9668003040054
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex gap-4">
                        <span className="shrink-0 text-gray-500">
                          <i className="fa-solid fa-location-dot fa-lg" />
                        </span>
                        <div className="space-y-1 flex-1">
                          <p className="text-gray-500 font-semibold text-sm">
                            الموقع
                          </p>
                          <a
                            href="https://g.page/MindITksa?share"
                            className="font-semibold text-lg table"
                          >
                            Kuwait, Kuwait city
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex gap-4">
                        <span className="shrink-0 text-gray-500">
                          <i className="fa-solid fa-phone fa-lg" />
                        </span>
                        <div className="space-y-1 flex-1">
                          <p className="text-gray-500 font-semibold text-sm">
                            رقم الهاتف
                          </p>
                          <a
                            href="http://we.me/9668003040054"
                            dir
                            className="font-semibold text-lg table tabular-nums"
                          >
                            96522006670
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex gap-4">
                        <span className="shrink-0 text-gray-500">
                          <i className="fa-regular fa-envelope fa-lg" />
                        </span>
                        <div className="space-y-1 flex-1">
                          <p className="text-gray-500 font-semibold text-sm">
                            عنوان البريد الالكترونى
                          </p>
                          <a
                            href="mailto:info@berry.com.sa"
                            className="font-semibold text-lg table"
                          >
                            info@berry.com.sa
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="md:w-1/2 md:mt-0 mt-4">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13681.640370242743!2d41.0071793!3d30.9869404!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf1d067beaeef058c!2sberry!5e0!3m2!1sen!2seg!4v1672096428580!5m2!1sen!2seg"
                    width={600}
                    height={450}
                    allowFullScreen="allowfullscreen"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    className="max-w-full"
                    style={{ border: "0px" }}
                  />
                </div>
              </div>
            </main>
          </section>
        </div>
      </Layout>
    </SimpleLocalize>
  );
};
export default About;
