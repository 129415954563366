import React from "react";
import icon1 from "./icons/feature-1.webp";
import icon2 from "./icons/feature-2.webp";
import icon3 from "./icons/feature-3.webp";
import icon4 from "./icons/feature-4.webp";
import icon5 from "./icons/feature-5.webp";
import icon6 from "./icons/feature-6.webp";
import "./feature.scss";

export default function Features() {
  const features = [
    {
      icon: icon1,
      heading: "فواتير دقيقة",
      caption:
        "يمكنك النظام من إنشاء الفواتير بمختلف انواعها , مبيعات , مشتريات , مرتجعات , تحويلات مخازن وغيره , وإمكانية التعديل والحذف والطباعة",
    },
    {
      icon: icon2,
      heading: "صلاحيات مستخدمين",
      caption:
        "يمكنك النظام من إدارة صلاحيات جميع اركان البرنامج , حتى يمكنك تعيين صلاحيات مختلفة لكل موظف لديك في المنشأة",
    },
    {
      icon: icon3,
      heading: "إدارة شئون موظفين",
      caption:
        "يمكنك النظام من التحكم فى الموارد البشرية داخل منشأتك واستعراض ملفات كل موظف , و الاطلاع على البيانات والشئون المالية الخاصة بكل موظف",
    },
    {
      icon: icon4,
      heading: "دعم فنى متواصل",
      caption:
        "نؤمن فى مايند اكونتينج بمدى أهمية الدعم الفنى , لذا نوفر لك دعم فنى متجاوب لك على مدار اليوم",
    },
    {
      icon: icon5,
      heading: "تقارير لحظية",
      caption:
        "نوفر لك كل التقارير اللازمة لنشاطك المحاسبى , تساعدك تقاريرنا على استرجاع اى معلومات مهمة فى اى وقت فى السنة",
    },
    {
      icon: icon6,
      heading: "نظام سحابى بالكامل",
      caption:
        "كل ماتحتاجه هو متصفح متصل بالانترنت حتى تستطيع بدء نشاطك المحاسبى معنا",
    },
  ];
  return (
    <section className="features-section">
      <div className="container mx-auto">
        <div className="text-center">
          <h2 className="section-heading inline-block">مزايا النظام</h2>
        </div>

        <div className="flex -mx-3 flex-wrap mt-16">
          {features.map((f, i) => (
            <div
              className={`px-3 md:w-1/3 wow animate__fadeInUp`}
              data-wow-duration={`${i === 0 ? 1 : 2 + i / 10}s`}
              key={f.heading}
            >
              <div className={`bg-white rounded px-4 py-8 mb-4`}>
                <img
                  width={98}
                  height={99}
                  className="mb-2"
                  src={f.icon}
                  alt={f.caption}
                />
                <h3 className="font-medium mb-3">{f.heading}</h3>
                <p className="text-xs text-gray-500">{f.caption}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
