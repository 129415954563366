import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { customersSlick } from "../../variables";
import "./customers.scss";
import React from "react";
import customer1 from "./customer1.png";
import customer2 from "./customer2.png";
import customer3 from "./customer3.png";
import customer4 from "./customer4.png";
import customer5 from "./customer5.png";
import customer6 from "./customer6.png";

export default function Customers() {
  const logos = [
    customer1,
    customer2,
    customer3,
    customer4,
    customer5,
    customer6,
  ];
  return (
    <div className="container md:pb-0 pb-12">
      <Slider {...customersSlick}>
        {logos.map((f, i) => (
          <div className="relative" key={`customer-${i}`}>
            <img
              className="customer_logo"
              width={150}
              src={f}
              alt={`customer-${i}`}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
