import React from "react";

export function Header({ title, caption1, caption2, children }) {
  return (
    <div className="py-16 bg-[#706fd3] text-white text-center pt-28">
      <div className="container">
        <h1 className="font-medium text-slate-200 text-primary mb-2 block">
          {title}
        </h1>
        <h2 className="font-bold text-2xl">{caption1}</h2>
        {caption2 ? (
          <p className="text-sm text-body-color mt-4">{caption2}</p>
        ) : null}
        {children}
      </div>
    </div>
  );
}
