import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { slickSettings } from "../../variables";
import qouteIcon from "./quote-right.svg";
import "./sectors.scss";
import avatar from "./male-avatar.webp";
import goldenMask from "../Customers/customer1.png";
import aofiLogo from "../Customers/customer6.png";
import shaeeLogo from "../Customers/customer5.png";
import logo from "../../images/mindpos-logo.png";

export default function Sectors() {
  const sectors = [
    {
      heading: "قطاع التجزئة",
      caption:
        "يمكنك اختيار عملاء ومودرين التجزئة , كذلك انشاء الاصناف كوحدات منفصلة او مجموعة, ونوفر لك احصائيات مبيعاتك ومشترياتك كذلك اكثر المنتجات مبيعاً",
    },
    {
      heading: "القطاع الخدمى",
      caption:
        "نقوم بخدمة المطاعم والكافيهات وكذلك الانشطة الخدمية الاخرى وتحديد التكاليف والارباح",
    },
    {
      heading: "المراكز الرياضية",
      caption:
        "يمكنك متابعة عملائك وموظفينك فى مركزك الرياضى , وكذلك حساب مصروفاتك واصدار الفواتير",
    },
    {
      heading: "القطاع التقنى",
      caption:
        "يرتكز البرنامج على اعمدة اساسية فى المحاسبة تمكن القطاع التقنى من استخدامه ليتناسب مع نشاطه",
    },
    {
      heading: "مكاتب المحاماة",
      caption:
        "تابع عملائك من الموكلين والمحامين , وقم بمتابعة نشاطك المحاسبى واصدار التقارير ",
    },
    {
      heading: "العيادات والمستشفيات",
      caption:
        "تم تنفيذ البرنامج ايضاً ليتناسب مع القطاع الصحى و المستشفيات , من خلال التقارير الدورية واصدار الفواتير ومتابعة الموظفين",
    },
  ];
  const testimonials = [
    {
      clientName: "العوفى سنتر",
      jobTitle: "الوظيفة",
      comment:
        "هذه من الشركات اللى تعاملنا معاهم, لأكثر من 4 سنوات, عندهم الدعم الفني ممتاز",
      avatar: aofiLogo,
    },
    {
      clientName: "القناع الذهبى",
      jobTitle: "الوظيفة",
      comment: "من افضل البرامج اللى اتعاملنا معاهم",
      avatar: goldenMask,
    },
    {
      clientName: "النجم البارز",
      jobTitle: "الوظيفة",
      comment:
        "صار لى سنتين استخدم النظام , برنامج مناسب وممتاز وكل السوق يستخدمه",
      avatar: logo,
    },
    {
      clientName: "محامص وتمور الشايع",
      jobTitle: "الوظيفة",
      comment: "افضل برنامج استخدمناه , كل فروع الشايع تعمل عليه",
      avatar: shaeeLogo,
    },
  ];
  return (
    <>
      <section className="py-24 invoice-section">
        <div className="container">
          <div className="md:flex items-center">
            <div
              className="flex-1 wow animate__fadeInUp"
              data-wow-duration="2s"
            >
              <h2 className="text-3xl font-bold mb-2">القطاعات</h2>
              <p className="mb-4 text-gray-500">
                قمنا بتنفيذ هذا النظام ليخدم بكل مرونة مختلف القطاعات والانشطة
              </p>
              <a href="#/" className="text-indigo-500 font-medium">
                المزيد من القطاعات
              </a>
            </div>

            <div className="flex-1 mt-8 md:mt-0">
              <div className="flex flex-wrap">
                {sectors.map((f, i) => (
                  <div
                    className={`px-3 md:w-1/2 mb-4 wow animate__fadeInUp`}
                    data-wow-duration={`${i + 1 * 1.005}s`}
                    key={f.heading}
                  >
                    <div
                      className={`bg-white shadow-sm rounded-md px-4 py-8 h-full`}
                    >
                      <h3 className="font-medium mb-3">{f.heading}</h3>
                      <p className="text-xs text-gray-500">{f.caption}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-8" dir="rtl">
          <div className="text-center">
            <h2 className="section-heading inline-block mb-12">اراء العملاء</h2>
          </div>
          <Slider {...slickSettings}>
            {testimonials.map((f, i) => (
              <div
                className="relative mx-4 text-right h-100"
                key={`testimonial${i}`}
              >
                <div className="bg-white rounded-md shadow-md px-4 pt-12 pb-14 h-100">
                  <img
                    width={20}
                    src={qouteIcon}
                    alt="رأى العميل"
                    className="mb-2 ml-auto d-block"
                  />
                  <p className="text-xs leading-6 text-gray-500 h-12">
                    {f.comment}
                  </p>
                </div>
                <div className="-mt-12 px-4 flex flex-col items-end">
                  <div className="bg-white w-16 h-16 shadow rounded-full p-2">
                    <img
                      src={f.avatar}
                      alt="اسم العميل"
                      className="rounded-full w-12 h-12 mb-2"
                    />
                  </div>
                  <p className="text-xs text-gray-600 text-md">
                    {f.clientName}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </>
  );
}
