import { graphql } from "gatsby";
import React, { useEffect } from "react";
import Hero from "../components/Hero/Hero";
import About from "../components/About/About";
import Features from "../components/features/Features";
import Invoicing from "../components/Invoicing/Invoicing";
import WarehouseHR from "../components/Warehouse/Warehouse";
import Whyus from "../components/WhyUs/Whyus";
import berryPosLogo from "../components/Integration/berry-pos.svg";
import berryPetrolLogo from "../components/Integration/berry-petrol.svg";
import berryBookingLogo from "../components/Integration/berry-booking.svg";
import SettingsReports from "../components/SettingsReports/SettingsReports";
import { SITE_URL } from "../variables";
import "animate.css";
import Sectors from "../components/Sectors/Sectors";
import Layout from "../components/layout";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import Contact from "../components/Contact/contact";
import SimpleLocalize from "../components/i18n/IntlProvider";
import { FormattedMessage } from "react-intl";
import POS from "../components/POS/pos";
import Customers from "../components/Customers/customers";

// markup
const IndexPage = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    import("wowjs").then(({ WOW }) => {
      const options = {
        boxClass: "wow",
        animateClass: "animate__animated",
        offset: 200,
        mobile: true,
        live: true,
      };
      new WOW().init(options);
    });
  }, []);

  return (
    <SimpleLocalize {...props}>
      <Layout t={t}>
        <Hero />
        <Customers />
        <About />
        <Features />
        <Invoicing />

        {/* POS section */}
        <POS />
        <section className="bg-indigo-500 py-32">
          <div className="container">
            <div
              className="md:flex items-center wow animate__fadeInUp"
              data-wow-duration="2s"
            >
              <div className="flex-1">
                <h2 className="text-2xl text-white font-bold mb-2">
                  التطبيق الأفضل والأكثر إعتمادية
                </h2>
                <p className="text-slate-200">
                  إمكانية تخصيص نظام دفترة حسب مجال عملك
                </p>
              </div>
              <div className="flex-1 md:mt-0 mt-4 md:text-left">
                <a
                  href={`${SITE_URL}/dashboard`}
                  className="btn btn--bg-white font-medium"
                >
                  ابدء تجربتك
                </a>
              </div>
            </div>
          </div>
        </section>
        <WarehouseHR />
        <Whyus />
        <Sectors />
        {/* <section className="py-24 wow">
          <div
            className="text-center wow animate__fadeInUp"
            data-wow-duration="2s"
          >
            <h2 className="text-2xl font-bold mb-4">الربط الإلكترونى</h2>
            <p className="mb-12">
              نوفر لك إمكانية ربط البرنامج مع برامجنا الإدارية الأخرى بدون عناء
              و خطوات كثيرة
            </p>
          </div>
          <div className="flex justify-center">
            <div className="px-8 text-center">
              <img loading="lazy" src={berryBookingLogo} alt="بيرى بوكينج" />
              <h3 className="font-medium mt-4">بيري بوكينج</h3>
            </div>
            <div className="px-8 text-center">
              <img loading="lazy" src={berryPetrolLogo} alt="بيرى بترول" />
              <h3 className="font-medium mt-4">بيري بترول</h3>
            </div>
            <div className="px-8 text-center">
              <img loading="lazy" src={berryPosLogo} alt="بيرى بوس" />
              <h3 className="font-medium mt-4">بيري بوس</h3>
            </div>
          </div>
        </section> */}
        <SettingsReports />

        {/* Contact Section */}
        <section className="mb-16 pt-16" id="contact">
          <div className="container">
            <h2 className="text-3xl font-bold mb-2 text-center">اتصل بنا</h2>
            <p className="text-sm grey text-center mb-16">
              يمكنك الأن ملئ البيانات التالية وسيقوم أحد مسئولينا بالتواصل معكم
            </p>
          </div>
          <Contact type="users" />
        </section>
        {/* Contact Section */}

        <section className="bg-purple-50 py-24">
          <div className="container">
            <div className="md:flex items-center">
              <div className="flex-1">
                <h2 className="text-2xl font-bold mb-2">
                  لديك استفسارات بخصوص البرنامج ؟
                </h2>
                <p className="text-gray-600">
                  يمكنك طلب استشارة وسيقوم احد مستشارينا بالتواصل معك والاجابة
                  على كل استفساراتك
                </p>
              </div>
              <div className="flex-1 md:mt-0 mt-4 md:text-left">
                <a href="#contact" className="btn btn--bg-purple font-medium">
                  تواصل معنا
                </a>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </SimpleLocalize>
  );
};

export default IndexPage;

// export const query = graphql`
//   query ($language: String!) {
//     locales: allLocale(filter: { language: { eq: $language } }) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `;
