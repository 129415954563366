import React from "react";
import { SITE_URL } from "../../variables";
import "./hero.scss";
// import macbook from "./hero_macbook.webp";
import isobg from "./iso-bg.webp";

export default function Hero() {
  return (
    <div className="hero-section flex-col mb-8">
      <div className="container my-auto">
        <div className="md:flex items-center">
          <div
            className="md:w-1/2 w-full text-center md:text-right wow animate__fadeInUp"
            data-wow-duration="2s"
          >
            <h2 className="text-sm text-slate-50 mb-2">مايند اكونتينج</h2>
            <h3 className=" text-2xl text-white font-bold mb-3">
              نظام محاسبى متكامل لإدارة مشروعك
            </h3>
            <p className="text-slate-100 leading-8 mb-6 md:none block">
              نصمم مستقبل البيع بالتجزئة
            </p>
            <p className="text-slate-100 leading-8 mb-6 md:block hidden">
              يعد مايند اكونتينج هو البرنامج المثالي لاصحاب المنشأت والمشاريع ,
              يساعدهم في إدارة جميع الجوانب الإِدارية , وبواجهة برمجية سهلة
              الاستخدام حتى تتيح لكل افراد المنشأة او المشروع العمل عليه بسلاسة
            </p>
            <a href={`${SITE_URL}/dashboard`} className="btn btn--bg-white">
              اشترك الأن
            </a>
          </div>
          <div
            className="md:w-1/2 w-full mt-16 md:block hidden md:mt-0 wow animate__fadeInUp "
            data-wow-duration="2s"
          >
            <img src={isobg} alt="bg" loading="lazy" height={397} />
          </div>
        </div>
      </div>
    </div>
  );
}
