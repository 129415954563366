import React from "react";
import invoicingImg from "../../images/cash-mind.png";
import invoiceIcon from "./invoice-icon.webp";
import "./invoice.scss";

export default function POS() {
  return (
    <section className="py-24 invoice-section">
      <div className="container">
        <div className="md:flex">
          <div
            className="flex-1 text-left mt-8 md:mt-0 wow animate__fadeInUp"
            data-wow-duration="2s"
          >
            <img
              className="inline-block"
              src={invoicingImg}
              alt="الفوترة الالكترونية"
              width={476}
              height={451}
            />
          </div>

          <div className="flex-1 md:flex items-center">
            <div
              className="flex-1 wow animate__fadeInUp"
              data-wow-duration="2s"
            >
              <h2 className="text-3xl font-bold mb-2">نقاط بيع متطورة</h2>
              <p className="mb-4 text-gray-500">
                يتميز النظام بإمتلاك افضل نقاط البيع السحابية بالعالم
              </p>
              <ul>
                <li className="flex items-center mb-4">
                  <img
                    width="40"
                    height="80"
                    className="ml-2"
                    src={invoiceIcon}
                    alt="ايقونة فواتير"
                  />
                  التخصيص الكامل للطلب
                </li>
                <li className="flex items-center mb-4">
                  <img
                    width="40"
                    height="80"
                    className="ml-2"
                    src={invoiceIcon}
                    alt="ايقونة فواتير"
                  />
                  إعداد اختيار العميل
                </li>
                <li className="flex items-center mb-4">
                  <img
                    width="40"
                    height="80"
                    className="ml-2"
                    src={invoiceIcon}
                    alt="ايقونة فواتير"
                  />
                  إعدادت للطباعة
                </li>
                <li className="flex items-center mb-4">
                  <img
                    width="40"
                    height="80"
                    className="ml-2"
                    src={invoiceIcon}
                    alt="ايقونة فواتير"
                  />
                  فواتير مردودات المشتريات
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
