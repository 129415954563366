import { Link } from "gatsby";
import React from "react";
import { Header } from "../components/Header/Header";
import Layout from "../components/layout";
import Contact from "../components/Contact/contact";
import useContact from "../hooks/useContact";

const KSA = () => {
  const { success, handleInputChange, handleSubmit, formData } =
    useContact("offerksa");
  return (
    <Layout>
      <Header
        title="العروض"
        caption1="عرض يوم التأسيس , خصم كبير على الإشتراك لفترة محدودة"
      />

      <div className="container my-16">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col md:flex-row gap-14 items-center">
            <div className="lg:w-2/5 md:w-2/4 w-full md:mx-0 mx-auto md:order-1 order-2">
              <img src="./Mind-Accounting.png" />
            </div>
            <div className="lg:w-2/5 md:w-2/4 w-full md:mx-0 mx-auto md:order-2 order-1">
              <div class="text-center mb-3">
                <span class="inline-block bg-[#706fd3] text-xs mx-1 px-3 py-2 text-white rounded-full mb-2">
                  نقاط بيع
                </span>
                <span class="inline-block bg-[#706fd3] text-xs mx-1 px-3 py-2 text-white rounded-full mb-2">
                  إدارة مخازن
                </span>
                <span class="inline-block bg-[#706fd3] text-xs mx-1 px-3 py-2 text-white rounded-full mb-2">
                  أصول ثابتة
                </span>
                <span class="inline-block bg-[#706fd3] text-xs mx-1 px-3 py-2 text-white rounded-full mb-2">
                  تقارير محاسبية
                </span>
                <span class="inline-block bg-[#706fd3] text-xs mx-1 px-3 py-2 text-white rounded-full mb-2">
                  قيود يومية
                </span>
              </div>
              <div className="relative mt-6">
                <p class="text-sm text-slate-900 text-center mb-8">
                  يمكنك الأن ملئ البيانات التالية وسيقوم أحد مسئولينا بالتواصل
                  معكم
                </p>
                <input
                  type="text"
                  className="peer w-full border-b placeholder:text-transparent py-3 px-4"
                  placeholder="الأسم"
                  id="name"
                  name="userName"
                  onChange={handleInputChange}
                  value={formData.userName}
                />
                <label
                  htmlFor="name"
                  className="absolute right-0 ml-1 -translate-y-8 bg-white px-1 text-sm duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:px-1 peer-focus:text-sm"
                >
                  أسم المؤسسة
                </label>
              </div>

              <div className="relative mt-6">
                <input
                  type="text"
                  className="peer w-full border-b placeholder:text-transparent py-3 px-4"
                  placeholder="phone"
                  name="phone"
                  id="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
                <label
                  htmlFor="email"
                  className="absolute right-0 ml-1 px-1 -translate-y-8 bg-white text-sm duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:px-1 peer-focus:text-sm"
                >
                  رقم الجوال
                </label>
              </div>
              {success ? (
                <div className="text-green-600 text-center medium_font mt-8">
                  تم إرسال البيانات الخاصة بكم وسيتم التواصل معكم بأقرب وقت
                </div>
              ) : null}
              <button className="mt-10 w-full btn btn--bg-purple font-medium">
                إرسال البيانات
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};
export default KSA;
