import React from "react";
import "./whyus.scss";
import chartLine from "./icons/chart-line.svg";
import cloud from "./icons/cloud.svg";
import globe from "./icons/globe.svg";
import laptop from "./icons/laptop.svg";
import shield from "./icons/shield.svg";
import siteMap from "./icons/sitemap.svg";
import undo from "./icons/undo.svg";
import userCog from "./icons/user-cog.svg";

export default function Whyus() {
  const whyUsList = [
    {
      icon: siteMap,
      heading: "مرونة البرنامج",
      caption: "يتسم البرنامج بالمرونة المطلقة في كل جوانبه",
    },
    {
      icon: laptop,
      heading: "سهولة الاستخدام",
      caption:
        "لايشترط المامك بالنشاط المحاسبى حتى تقوم باى نشاط داخل البرنامج",
    },
    {
      icon: userCog,
      heading: "دعم فنى مجاناً",
      caption:
        "نوفر لك الدعم الفني المتواصل على مدار الأسبوع بدون اى تكاليف إضافية",
    },
    {
      icon: globe,
      heading: "سرعة التصفح",
      caption: "تستطيع التنقل بمنتهى السرعة داخل البرنامج بين عناصر مشروعك",
    },
    {
      icon: chartLine,
      heading: "تقارير فورية",
      caption:
        "يمكنك اصدار التقارير و تصفية نتائجها بفلتر بحث مخصص وبالمدة اللتى تفضلها",
    },
    {
      icon: undo,
      heading: "تحديثات مستمرة",
      caption: "نقوم بتحديث البرنامج بشكل مستمر لإضافة مميزات جديدة او التطوير",
    },
    {
      icon: shield,
      heading: "الأمن والحماية",
      caption:
        "اهم مايميز البرنامج الحماية لأننا نعتمد على احدث التقنيات وامنهم",
    },
    {
      icon: cloud,
      heading: "متوفر من اى مكان",
      caption: "تستطيع بإتصال انترنت بسيط الدخول على البرنامج من اى مكان",
    },
  ];
  return (
    <section className="whyus-section py-32">
      <div className="container">
        <h2 className="text-2xl font-bold mb-4">
          لماذا يعد مايند اكونتينج البرنامج المتكامل لك ؟
        </h2>
        <p className="mb-8">
          يعد مايند اكونتينج هو البرنامج المثالي لاصحاب المنشأت والمشاريع ,
          يساعدهم في إدارة جميع الجوانب الإِدارية
        </p>

        <div className="flex flex-wrap -mx-4 ">
          {whyUsList.map((w, i) => (
            <div
              className="md:w-1/4 w-1/2 mt-8 px-4 wow animate__fadeInUp"
              data-wow-duration={`${i === 0 ? 1 : 2 + i / 10}s`}
              key={`whyIcon${i}`}
            >
              <span className="w-12 rounded-full mb-4 justify-center h-12 flex items-center bg-indigo-500 text-white">
                <img className="inline-block" src={w.icon} alt={w.heading} />
              </span>
              <h3 className="font-medium mb-2">{w.heading}</h3>
              <p className="text-xs text-gray-500">{w.caption}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
