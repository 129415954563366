import React from "react";
// import invoicingImg from "./invoicing-img.webp";
import store from "./store2.webp";
import invoiceIcon from "./invoice-icon.webp";
import "./invoice.scss";

export default function Invoicing() {
  return (
    <section className="py-24 invoice-section">
      <div className="container">
        <div className="md:flex items-center">
          <div className="flex-1 wow animate__fadeInUp" data-wow-duration="2s">
            <h2 className="text-3xl font-bold mb-2">الفوترة الإلكترونية</h2>
            <p className="mb-4 text-gray-500">
              يمكنك النظام من إنشاء الفواتير وطباعتها باى وقت
            </p>
            <ul>
              <li className="flex items-center mb-4">
                <img
                  width="40"
                  height="80"
                  className="ml-2"
                  src={invoiceIcon}
                  alt="ايقونة فواتير"
                />
                فواتير مبيعات
              </li>
              <li className="flex items-center mb-4">
                <img
                  width="40"
                  height="80"
                  className="ml-2"
                  src={invoiceIcon}
                  alt="ايقونة فواتير"
                />
                فواتير مشتريات
              </li>
              <li className="flex items-center mb-4">
                <img
                  width="40"
                  height="80"
                  className="ml-2"
                  src={invoiceIcon}
                  alt="ايقونة فواتير"
                />
                فواتير مردودات المبيعات
              </li>
              <li className="flex items-center mb-4">
                <img
                  width="40"
                  height="80"
                  className="ml-2"
                  src={invoiceIcon}
                  alt="ايقونة فواتير"
                />
                فواتير مردودات المشتريات
              </li>
            </ul>
          </div>
          <div
            className="flex-1 text-left mt-8 md:mt-0 wow animate__fadeInUp"
            data-wow-duration="2s"
          >
            <img
              className="inline-block"
              src={store}
              alt="الفوترة الالكترونية"
              width={390}
              height={479}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
