import { Header } from "../components/Header/Header";
import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { getPlansInfo } from "../content/plans";
import { UserIcon, LibraryIcon } from "@heroicons/react/outline";
import { SITE_URL } from "../variables";

const Pricing = () => {
  const [apiPlans, setPlans] = useState([]);

  useEffect(() => {
    getPlansInfo("ar")
      .then((res) => setPlans(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  const plans = [
    {
      id: 1,
      title: "السنوية",
      caption:
        "خطة مناسبة جداً للانشطة المتوسطة والشركات متوسطة الحجم تدفع سنوياً",
      price: "170",
      features: [
        "الفواتير",
        "الحسابات العامة",
        "المخازن",
        "الفروع",
        "الموظفين",
        "التقارير",
        "الاصول الثابتة",
        "دليل الحسابات",
        "مراكز التكلفة",
        "المستخدمين",
        "اعدادات كاملة",
      ],
    },
    {
      id: 2,
      title: "الشهرية",
      caption:
        "مناسبة جداً اذا تبدء نشاط جديد وتريد حل محاسبى غير مكلف, تدفع شهرياً",
      price: "210",
      features: [
        "الفواتير",
        "الحسابات العامة",
        "المخازن",
        "الفروع",
        "الموظفين",
        "التقارير",
        "الاصول الثابتة",
        "دليل الحسابات",
        "مراكز التكلفة",
        "المستخدمين",
        "اعدادات كاملة",
      ],
    },

    {
      id: 3,
      title: "السنوية + نقطة البيع",
      caption:
        "خطة مناسبة جداً للانشطة المتوسطة والشركات متوسطة الحجم, تدفع سنوياً",
      price: "215",
      features: [
        "الفواتير",
        "الحسابات العامة",
        "المخازن",
        "الفروع",
        "الموظفين",
        "التقارير",
        "الاصول الثابتة",
        "نقطة البيع",
        "مراكز التكلفة",
        "المستخدمين",
        "اعدادات كاملة",
      ],
    },
  ];
  const circles = (
    <div>
      <span className="absolute right-0 top-7 z-[-1]">
        <svg
          width={77}
          height={172}
          viewBox="0 0 77 172"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx={86} cy={86} r={86} fill="url(#paint0_linear)" />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1={86}
              y1={0}
              x2={86}
              y2={172}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#706FD3" stopOpacity="0.09" />
              <stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
            </linearGradient>
          </defs>
        </svg>
      </span>
      <span className="absolute right-4 top-4 z-[-1]">
        <svg
          width={41}
          height={89}
          viewBox="0 0 41 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="38.9138"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 38.9138 87.4849)"
            fill="#706FD3"
          />
          <circle
            cx="38.9138"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 38.9138 74.9871)"
            fill="#706FD3"
          />
          <circle
            cx="38.9138"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 38.9138 62.4892)"
            fill="#706FD3"
          />
          <circle
            cx="38.9138"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 38.9138 38.3457)"
            fill="#706FD3"
          />
          <circle
            cx="38.9138"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 38.9138 13.634)"
            fill="#706FD3"
          />
          <circle
            cx="38.9138"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 38.9138 50.2754)"
            fill="#706FD3"
          />
          <circle
            cx="38.9138"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 38.9138 26.1319)"
            fill="#706FD3"
          />
          <circle
            cx="38.9138"
            cy="1.42021"
            r="1.42021"
            transform="rotate(180 38.9138 1.42021)"
            fill="#706FD3"
          />
          <circle
            cx="26.4157"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 26.4157 87.4849)"
            fill="#706FD3"
          />
          <circle
            cx="26.4157"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 26.4157 74.9871)"
            fill="#706FD3"
          />
          <circle
            cx="26.4157"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 26.4157 62.4892)"
            fill="#706FD3"
          />
          <circle
            cx="26.4157"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 26.4157 38.3457)"
            fill="#706FD3"
          />
          <circle
            cx="26.4157"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 26.4157 13.634)"
            fill="#706FD3"
          />
          <circle
            cx="26.4157"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 26.4157 50.2754)"
            fill="#706FD3"
          />
          <circle
            cx="26.4157"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 26.4157 26.1319)"
            fill="#706FD3"
          />
          <circle
            cx="26.4157"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 26.4157 1.4202)"
            fill="#706FD3"
          />
          <circle
            cx="13.9177"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 13.9177 87.4849)"
            fill="#706FD3"
          />
          <circle
            cx="13.9177"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 13.9177 74.9871)"
            fill="#706FD3"
          />
          <circle
            cx="13.9177"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 13.9177 62.4892)"
            fill="#706FD3"
          />
          <circle
            cx="13.9177"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 13.9177 38.3457)"
            fill="#706FD3"
          />
          <circle
            cx="13.9177"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 13.9177 13.634)"
            fill="#706FD3"
          />
          <circle
            cx="13.9177"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 13.9177 50.2754)"
            fill="#706FD3"
          />
          <circle
            cx="13.9177"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 13.9177 26.1319)"
            fill="#706FD3"
          />
          <circle
            cx="13.9177"
            cy="1.42019"
            r="1.42021"
            transform="rotate(180 13.9177 1.42019)"
            fill="#706FD3"
          />
          <circle
            cx="1.41963"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 1.41963 87.4849)"
            fill="#706FD3"
          />
          <circle
            cx="1.41963"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 1.41963 74.9871)"
            fill="#706FD3"
          />
          <circle
            cx="1.41963"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 1.41963 62.4892)"
            fill="#706FD3"
          />
          <circle
            cx="1.41963"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 1.41963 38.3457)"
            fill="#706FD3"
          />
          <circle
            cx="1.41963"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 1.41963 13.634)"
            fill="#706FD3"
          />
          <circle
            cx="1.41963"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 1.41963 50.2754)"
            fill="#706FD3"
          />
          <circle
            cx="1.41963"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 1.41963 26.1319)"
            fill="#706FD3"
          />
          <circle
            cx="1.41963"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 1.41963 1.4202)"
            fill="#706FD3"
          />
        </svg>
      </span>
    </div>
  );
  const timesIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 ml-2 text-gray-200"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
  const checkIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 ml-2 text-indigo-700"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
  const toggleAnnual = (evt, index) => {
    const plans = [...apiPlans];
    plans[index].activeAnnual = evt.target.checked;
    setPlans(plans);
  };
  return (
    <Layout>
      <Header
        title="الاشتراكات"
        caption1="خطط اسعار مايند اكونتينج"
        caption2="نقدم لكم باقة متنوعة من الخطط تناسب جميع النشاطات والاحتياجات , مع
            إمكانية الترقية فى اى وقت"
      />
      <div className="container">
        <div className="flex flex-wrap justify-center -mt-8 -mx-4">
          {apiPlans.map((p, i) => (
            <div className="w-full md:w-1/2 lg:w-1/3 px-4" key={`plan-${i}`}>
              <div className="bg-white rounded-xl relative z-10 overflow-hidden border border-primary border-opacity-20 shadow-pricing py-10 px-8 sm:p-12 lg:py-10 lg:px-6 xl:p-12 mb-10">
                <span className="text-primary font-semibold text-lg block mb-4">
                  {p.name}
                </span>
                <div className="flex justify-between">
                  <h2 className="font-bold text-dark text-[42px]">
                    <small className="text-gray-500 text-3xl font-bold mr-1 ml-2">
                      $
                    </small>
                    <span className="text-indigo-700">
                      {p.activeAnnual ? p.annualPrice : p.price}
                    </span>

                    <span className="text-base text-body-color font-medium">
                      / شهرياً
                    </span>
                  </h2>
                  <label
                    className="inline-flex items-center"
                    htmlFor={`annual${i}`}
                  >
                    <input
                      onChange={(e) => toggleAnnual(e, i)}
                      id={`annual${i}`}
                      type="checkbox"
                    />
                    <span className="text-xs mr-1">سنوى</span>
                  </label>
                </div>
                {p.activeAnnual && (
                  <small className="font-bold text-gray-600">
                    يدفع {p.annualTotalPrice}$ سنوياً
                  </small>
                )}

                <p className="text-sm text-body-color mt-5 mb-6">
                  {p.description}
                </p>

                <div className="mb-7 border-t pt-6 border-[#F2F2F2]">
                  {[
                    { key: "pos", label: "نقطة البيع" },
                    { key: "hr", label: "الموارد البشرية" },
                    { key: "accounting", label: "المحاسبة" },
                    { key: "asset", label: "الأصول الثابتة" },
                    { key: "linking", label: "الربط مع البرامج" },
                  ].map((f, i) => (
                    <p
                      key={`features-${i}`}
                      className="text-base text-body-color leading-loose mb-1 flex items-center"
                    >
                      {p[f.key] ? checkIcon : timesIcon}
                      {f.label}
                    </p>
                  ))}
                  <div className="flex flex-wrap mt-6 mb-6 border-t py-6 bg-purple-100 pr-10 pl-4 -mx-12 text-indigo-700">
                    <div className="px-3 md:w-1/2">
                      <div className="flex">
                        {new Array(p.userCount).fill().map((icon, i) => (
                          <UserIcon key={`user${i}`} className="w-6 h-6" />
                        ))}
                      </div>

                      <div className="text-xs flex mb-4 mt-1">
                        {p.userCount} مستخدم
                      </div>
                    </div>
                    <div className="px-3 md:w-1/2">
                      <div className="text-sm flex justify-end mt-2 items-center text-gray-700 mb-4">
                        +
                        <UserIcon className="w-4 h-4 text-gray-400 ml-2 mr-1" />
                        {p.extraUserMonthlyPrice} $
                      </div>
                    </div>
                    <div className="px-3 md:w-1/2">
                      <div className="flex">
                        {new Array(p.warehouseCount).fill().map((icon, i) => (
                          <LibraryIcon key={`user${i}`} className="w-6 h-6" />
                        ))}
                      </div>
                      <div className="text-xs flex mt-1">
                        {p.warehouseCount} مخزن
                      </div>
                    </div>
                    <div className="px-3 md:w-1/2">
                      <div className="text-sm flex justify-end mt-2 items-center text-gray-700">
                        +
                        <LibraryIcon className="w-4 h-4 text-gray-400 ml-2 mr-1" />
                        {p.extraWarehouseMonthlyPrice} $
                      </div>
                    </div>
                  </div>
                </div>

                {circles}

                <a
                  href={`${SITE_URL}/dashboard/#/cart?planId=${p.id}`}
                  className={`w-full block text-base font-medium ${
                    i === 1
                      ? "bg-purple-700 text-white"
                      : "bg-transparent text-indigo-700 hover:text-white hover:bg-purple-700"
                  } border border-[#D4DEFF] rounded-md text-center p-4 hover:border-primary transition`}
                >
                  اشترك الأن
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};
export default Pricing;
