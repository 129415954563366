import React, { useState } from "react";
import { db } from "../util/firebase";
import { collection, addDoc } from "firebase/firestore";

export default function useContact(type) {
  // Initialize state for form input values
  const [formData, setFormData] = useState({
    userName: "",
    phone: "",
  });
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const fetchDataFromFirestore = async () => {
    try {
      await addDoc(collection(db, type), {
        userName: formData.userName,
        phone: formData.phone,
        date: new Date(),
      });
      setSuccess(true);
      setFormData({ userName: "", phone: "" });
    } catch (error) {
      console.error("Error fetching data from Firestore:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchDataFromFirestore();
  };
  return {
    formData,
    success,
    handleInputChange,
    handleSubmit,
  };
}
