import React from "react";
import reportsImg from "./reports-imgs.webp";
import settingsImg from "./settings2.webp";
import reports from "./reports.webp";
import "./warehouse.scss";
import settingIcon from "./cog.svg";
import chartIcon from "./chart-bar.svg";

export default function SettingsReports() {
  const settingImg = (
    <img className="ml-3" src={settingIcon} alt="ايقونة اعدادات" />
  );
  const chartImg = <img className="ml-3" src={chartIcon} alt="ايقونة تقارير" />;
  return (
    <div className="py-24 warehouseHRWrapper">
      <section>
        <div className="container">
          <div className="md:flex items-center">
            <div
              className="flex-1 wow animate__fadeInUp"
              data-wow-duration="2s"
            >
              <img
                className="inline-block"
                src={settingsImg}
                width={450}
                alt="الإعدادات"
                loading="lazy"
              />
            </div>
            <div className="flex-1">
              <div
                className="md:pr-8 mt-8 md:mt-0 wow animate__fadeInUp"
                data-wow-duration="2s"
              >
                <h2 className="text-3xl font-bold mb-2">إعدادات لكل شئ</h2>
                <p className="mb-4 text-gray-500">
                  يمكنك إعداد كل اركان نظام المحاسبى من خلال الاعدادات
                </p>
                <ul>
                  <li className="flex items-center mb-4">
                    {settingImg}
                    دليل الحسابات
                  </li>
                  <li className="flex items-center mb-4">
                    {settingImg}
                    إعدادات الضرائب
                  </li>
                  <li className="flex items-center mb-4">
                    {settingImg}
                    إعدادات الخزن
                  </li>
                  <li className="flex items-center mb-4">
                    {settingImg}
                    مراكز التكلفة
                  </li>
                  <li className="flex items-center mb-4">
                    {settingImg}
                    إمكانية انشاء فروع
                  </li>
                  <li className="flex items-center mb-4">
                    {settingImg}
                    المستخدمين
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-44">
        <div className="container">
          <div className="md:flex items-center">
            <div className="flex-1">
              <div
                className="md:pr-8 wow animate__fadeInUp"
                data-wow-duration="2s"
              >
                <h2 className="text-3xl font-bold mb-2">التقارير</h2>
                <p className="text-gray-500 mb-8">
                  مايند اكونتينج يقدم لك تقارير شاملة لكل ركن من اركان المشروع ,
                  بأكثر من 30 تقرير مختلف و بإمكانية طباعتها وتصفية نتائجها حسب
                  المطلوب
                </p>
                <ul>
                  <li className="flex items-center mb-4">
                    {chartImg}
                    دفتر الأستاذ
                  </li>
                  <li className="flex items-center mb-4">
                    {chartImg}
                    ميزان المراجعة
                  </li>
                  <li className="flex items-center mb-4">
                    {chartImg}
                    دليل الحسابات
                  </li>
                  <li className="flex items-center mb-4">
                    {chartImg}
                    الميزانية العمومية
                  </li>
                  <li className="flex items-center mb-4">
                    {chartImg}و اكثر من 25 تقرير اخر
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="flex-1 md:text-left md:mt-0 mt-8  wow animate__fadeInUp"
              data-wow-duration="2s"
            >
              <img
                className="inline-block"
                src={reports}
                alt="االفوترة الالكترونية"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
