import React from "react";
import warehouseImg from "./warehouse_img.webp";
import hrImg from "./hrr.webp";
import inventory from "./inventory.png";
import "./warehouse.scss";

export default function WarehouseHR() {
  const warehouseIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5 ml-2 text-gray-400"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
        clipRule="evenodd"
      />
    </svg>
  );
  return (
    <div className="pt-24 warehouseHRWrapper">
      <section>
        <div className="container">
          <div className="md:flex items-center">
            <div
              className="flex-1  wow animate__fadeInUp"
              data-wow-duration="2s"
            >
              <img
                className="inline-block"
                src={inventory}
                alt="إدارة المخازن"
                loading="lazy"
                width={380}
                height={479}
              />
            </div>
            <div className="flex-1">
              <div className="md:pr-8 mt-8 md:mt-0">
                <h2 className="text-3xl font-bold mb-2">إدارة المخزون</h2>
                <p className="mb-4 text-gray-500">
                  يمكنك النظام من إدارة المخازون والمخزون
                </p>
                <ul>
                  <li className="flex items-center mb-4">
                    {warehouseIcon}
                    تحويلات المخزون
                  </li>
                  <li className="flex items-center mb-4">
                    {warehouseIcon}
                    فواتير الهالك
                  </li>
                  <li className="flex items-center mb-4">
                    {warehouseIcon}
                    تسوية المخزون
                  </li>
                  <li className="flex items-center mb-4">
                    {warehouseIcon}
                    جرد المخازن
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-44">
        <div className="container">
          <div className="md:flex items-center">
            <div
              className="flex-1 wow animate__fadeInUp"
              data-wow-duration="2s"
            >
              <div className="md:pr-8">
                <h2 className="text-3xl font-bold mb-2">
                  إدارة الموارد البشرية
                </h2>
                <p className="text-gray-500 mb-8">
                  يعد مايند اكونتينج هو البرنامج المثالي لاصحاب المنشأت
                  والمشاريع , يساعدهم في إدارة الموظفين وكل مايتعلق بهم
                </p>
                <ul>
                  <li className="flex items-center mb-4">
                    {warehouseIcon}
                    إدارة شئون العاملين بالمؤسسة بملفاتهم
                  </li>
                  <li className="flex items-center mb-4">
                    {warehouseIcon}
                    الشئون المالية للموظفين ( رواتب , بدلات , خصومات , سلف ,
                    مكأفات
                  </li>
                  <li className="flex items-center mb-4">
                    {warehouseIcon}
                    متابعة الاجازات الخاصة بكل موظف
                  </li>
                  <li className="flex items-center mb-4">
                    {warehouseIcon}
                    ربط الموظفين بدليل الحسابات
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="flex-1 md:text-left md:mt-0 mt-8 "
              data-wow-duration="2s"
            >
              <img
                className="inline-block"
                src={hrImg}
                alt="الموارد البشرية"
                loading="lazy"
                width={390}
                height={479}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
