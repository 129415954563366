import React from "react";
import useContact from "../../hooks/useContact";

export default function Contact({ type }) {
  const { success, handleInputChange, handleSubmit, formData } =
    useContact(type);

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-8 max-w-md mx-auto">
        <div className="relative mt-6">
          <input
            type="text"
            className="peer w-full border-b placeholder:text-transparent py-3 px-4"
            placeholder="الأسم"
            id="name"
            name="userName"
            onChange={handleInputChange}
            value={formData.userName}
            required
          />
          <label
            htmlFor="name"
            className="absolute right-0 ml-1 -translate-y-8 bg-white px-1 text-sm duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:px-1 peer-focus:text-sm"
          >
            أسم المؤسسة
          </label>
        </div>

        <div className="relative mt-6">
          <input
            type="text"
            className="peer w-full border-b placeholder:text-transparent py-3 px-4"
            placeholder="phone"
            name="phone"
            id="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
          <label
            htmlFor="email"
            className="absolute right-0 ml-1 px-1 -translate-y-8 bg-white text-sm duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:px-1 peer-focus:text-sm"
          >
            رقم الجوال
          </label>
        </div>
        {success ? (
          <div className="text-green-600 text-center medium_font mt-8">
            تم إرسال البيانات الخاصة بكم وسيتم التواصل معكم بأقرب وقت
          </div>
        ) : null}
        <button className="mt-10 w-full btn btn--bg-purple font-medium">
          إرسال البيانات
        </button>
      </div>
    </form>
  );
}
